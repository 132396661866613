export default [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'whyhjz',
    label: 'Why hjz',
  },
  {
    path: 'HowUseIt',
    label: 'How it work',
  },
  {
    path: 'FAQs',
    label: 'FAQs',
  }
  // {
  //   path: 'testimonials',
  //   label: 'Testimonials',
  // },
];
